.radial-three-quarters>span.radial-01-border-l:after {
    content: " ";
    display: block;
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    background-color: #008cba;
    border-radius: 100%;
    z-index: 10;
    clip: rect(60px, 120px, 120px, 0px);
}


.radial-01 {
    float: left;
    position: relative;
    width: 100px;
    height: 100px;
    text-align: center;
    background-color: red;
    padding-top: 40px;
    border-radius: 100%;
    /* margin: 40px; */
}

/* .radial-01 p {
    position: absolute;
    left: -25px;
    bottom: -55px;
    z-index: 50;
    width: 150px;
} */

/* radial-01>span.radial-01-border-r:before {
    content: " ";
    display: block;
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    background-color: #ccc;
    border-radius: 100%;
    z-index: 5;
} */

.radial-01>span.radial-01-border-r:after {
    content: " ";
    display: block;
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    background-color: #008cba;
    border-radius: 100%;
    z-index: 10;
    clip: rect(0px, 120px, 120px, 60px);
}

/* .radial-01>span.radial-01-border-l:before {
    content: " ";
    display: block;
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    background-color: #333F50;
    border-radius: 100%;
    z-index: 5;
} */

.radial-01 .radial-01-number {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: #1E2736;
    border-radius: 100%;
    padding-top: 41px;
    z-index: 20;
}

.radial-01 .radial-01-number {
    font-size: 30px;
    padding-top: 32px;
}

.radial-01 .radial-01-number .radial-01-syb {
    font-size: 20px;
}