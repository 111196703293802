pre[class*='language-'],
code[class*='language-'] {
  color: #fff6f6;
  font-size: 13px;
  text-shadow: none;
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  direction: ltr;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

pre[class*='language-']::selection,
code[class*='language-']::selection {
  text-shadow: none;
  background: #006eff;
}

@media print {
  pre[class*='language-'],
  code[class*='language-'] {
    text-shadow: none;
  }
}

pre[class*='language-'] {
  padding: 1em;
  margin: 0.5em 0;
  overflow: auto;
  background: #1e1e1e;
}

:not(pre) > code[class*='language-'] {
  padding: 0.1em 0.3em;
  border-radius: 0.3em;
  color: #ff5678;
  background: #f9f2f4;
}
/*********************************************************
* Tokens
*/
.namespace {
  opacity: 0.7;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: #94ff63;
}

.token.punctuation {
  color: #d4d4d4;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
  color: #60ff7d;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #ffa17b;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  color: #ffffff;
  background: transparent;
}

.token.atrule,
.token.attr-value,
.token.keyword {
  color: #ff6bf3;
}

.token.function {
  color: #dcdcaa;
}

.token.regex,
.token.important,
.token.variable {
  color: #ff7c7c;
}

.token.important,
.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.constant {
  color: #7fd2ff;
}

.token.class-name {
  color: #61ffdf;
}

.token.parameter {
  color: #6acbff;
}

.token.interpolation {
  color: #76cfff;
}

.token.punctuation.interpolation-punctuation {
  color: #78c2ff;
}

.token.boolean {
  color: #75c1ff;
}

.token.property {
  color: #9cdcfe;
}

.token.selector {
  color: #ffd782;
}

.token.tag {
  color: #64b9ff;
}

.token.attr-name {
  color: #72ceff;
}

.token.attr-value {
  color: #ce9178;
}

.token.entity {
  color: #4ec9b0;
  cursor: unset;
}

.token.namespace {
  color: #4ec9b0;
}
/*********************************************************
* Language Specific
*/
pre[class*='language-javascript'],
code[class*='language-javascript'] {
  color: #4ec9b0;
}
pre[class*='language-python'],
code[class*='language-python'] {
  color: #61ffdf;
}

pre[class*='language-css'],
code[class*='language-css'] {
  color: #ffa784;
}

pre[class*='language-html'],
code[class*='language-html'] {
  color: #ffffff;
}

.language-html .token.punctuation {
  color: #ffffff;
}
/*********************************************************
* Line highlighting
*/
pre[data-line] {
  position: relative;
}

pre[class*='language-'] > code[class*='language-'] {
  position: relative;
  z-index: 1;
}

.line-highlight {
  position: absolute;
  left: 0;
  right: 0;
  padding: inherit 0;
  margin-top: 1em;
  background: #f7ebc6;
  box-shadow: inset 5px 0 0 #f7d87c;
  z-index: 0;
  pointer-events: none;
  line-height: inherit;
  white-space: pre;
}







/**
 * Solarized dark atom theme for `prism.js`
 * Based on Atom's `atom-dark` theme: https://github.com/atom/atom-dark-syntax
 * @author Pranay Chauhan (@PranayChauhan2516)
 */

 code[class*="language-"],
 pre[class*="language-"] {
     color: #839496;
     text-shadow: 0 1px rgba(0, 0, 0, 0.3);
     font-family: Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace;
     direction: ltr;
     text-align: left;
     white-space: pre;
     word-spacing: normal;
     word-break: normal;
     line-height: 1.5;
 
     -moz-tab-size: 4;
     -o-tab-size: 4;
     tab-size: 4;
 
     -webkit-hyphens: none;
     -moz-hyphens: none;
     -ms-hyphens: none;
     hyphens: none;
 }
 
 /* Code blocks */
 pre[class*="language-"] {
     padding: 1em;
     margin: .5em 0;
     overflow: auto;
     border-radius: 0.3em;
 }
 
 :not(pre) > code[class*="language-"],
 pre[class*="language-"] {
     background: #002b36;
 }
 
 /* Inline code */
 :not(pre) > code[class*="language-"] {
     padding: .1em;
     border-radius: .3em;
 }
 
 .token.comment,
 .token.prolog,
 .token.doctype,
 .token.cdata {
     color: #586e75;
 }
 
 .token.punctuation {
     color: #93a1a1;
 }
 
 .namespace {
     opacity: .7;
 }
 
 .token.property,
 .token.keyword,
 .token.tag {
     color: #268bd2;
 }
 
 .token.class-name {
     color: #FFFFB6;
     text-decoration: underline;
 }
 
 .token.boolean,
 .token.constant {
     color: #b58900;
 }
 
 .token.symbol,
 .token.deleted {
     color: #dc322f;
 }
 
 .token.number {
     color: #859900;
 }
 
 .token.selector,
 .token.attr-name,
 .token.string,
 .token.char,
 .token.builtin,
 .token.inserted {
     color: #859900;
 }
 
 .token.variable {
     color: #268bd2;
 }
 
 .token.operator {
     color: #EDEDED;
 }
 
 .token.function {
     color: #268bd2;
 }
 
 .token.regex {
     color: #E9C062;
 }
 
 .token.important {
     color: #fd971f;
 }
 
 .token.entity {
     color: #FFFFB6;
     cursor: help;
 }
 
 .token.url {
     color: #96CBFE;
 }
 
 .language-css .token.string,
 .style .token.string {
     color: #87C38A;
 }
 
 .token.important,
 .token.bold {
     font-weight: bold;
 }
 
 .token.italic {
     font-style: italic;
 }
 
 .token.atrule,
 .token.attr-value {
     color: #F9EE98;
 }



















 /**
 * a11y-dark theme for JavaScript, CSS, and HTML
 * Based on the okaidia theme: https://github.com/PrismJS/prism/blob/gh-pages/themes/prism-okaidia.css
 * @author ericwbailey
 */

code[class*="language-"],
pre[class*="language-"] {
	color: #f8f8f2;
	background: none;
	font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
	text-align: left;
	white-space: pre;
	word-spacing: normal;
	word-break: normal;
	word-wrap: normal;
	line-height: 1.5;

	-moz-tab-size: 4;
	-o-tab-size: 4;
	tab-size: 4;

	-webkit-hyphens: none;
	-moz-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;
}

/* Code blocks */
pre[class*="language-"] {
	padding: 1em;
	margin: 0.5em 0;
	overflow: auto;
	border-radius: 0.3em;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
	background: #2b2b2b;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
	padding: 0.1em;
	border-radius: 0.3em;
	white-space: normal;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
	color: #d4d0ab;
}

.token.punctuation {
	color: #fefefe;
}

.token.property,
.token.tag,
.token.constant,
.token.symbol,
.token.deleted {
	color: #ffa07a;
}

.token.boolean,
.token.number {
	color: #00e0e0;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
	color: #abe338;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string,
.token.variable {
	color: #00e0e0;
}

.token.atrule,
.token.attr-value,
.token.function {
	color: #ffd700;
}

.token.keyword {
	color: #00e0e0;
}

.token.regex,
.token.important {
	color: #ffd700;
}

.token.important,
.token.bold {
	font-weight: bold;
}

.token.italic {
	font-style: italic;
}

.token.entity {
	cursor: help;
}

@media screen and (-ms-high-contrast: active) {
	code[class*="language-"],
	pre[class*="language-"] {
		color: windowText;
		background: window;
	}

	:not(pre) > code[class*="language-"],
	pre[class*="language-"] {
		background: window;
	}

	.token.important {
		background: highlight;
		color: window;
		font-weight: normal;
	}

	.token.atrule,
	.token.attr-value,
	.token.function,
	.token.keyword,
	.token.operator,
	.token.selector {
		font-weight: bold;
	}

	.token.attr-value,
	.token.comment,
	.token.doctype,
	.token.function,
	.token.keyword,
	.token.operator,
	.token.property,
	.token.string {
		color: highlight;
	}

	.token.attr-value,
	.token.url {
		font-weight: normal;
	}
}